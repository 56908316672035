import {
  AccountInfoData,
  ActivityStepData,
  AuthData,
  AuthPassedResponse,
  BaseResponseData,
  BrandStepData,
  CheckCodeData,
  Cookies,
  LocationData,
  MeasurementsData,
  RegisterUserStep,
  CurrentUser,
  VerifyPhoneNumberData,
  AdminApi,
  AdminApiDisableAction,
  RegistrationStep,
  CommonAdminApiRequestData,
  ChangePasswordData,
  ResetPasswordDate,
  CMSPageIds,
  ProfileApi,
  ProfileInfo,
  ItemManagementApi,
  CustomerAmbassadorForList,
  ListResponseData,
  CustomerAmbassadorInstance,
  GearResponse,
  CmsSizeResponse,
  CustomerOffer,
  OfferType,
  OfferAction,
  CommonQuery,
  MessagesRoomsRequest,
  MessagesRoomMessageRequest,
  ProductInstance,
  ProductRelated,
  BasicSearchListRequestParams,
  ProductComment,
  ReportReasonOptions,
  CmsReportReasonResponse,
  PurchaseApiType,
  GearListRequest,
  PurchaseInstance,
  BasicPageListRequestParams,
  PayoutProps,
  QueryParams,
  OrderType,
  OrderActionType,
  LoginSocialData,
  CustomerApi,
  CustomerProfileDetailApi,
  ReviewsQuery,
  ReviewApi,
  DashboardApi,
  DashboardQueryParams,
  CancellationFeeData,
  DealTypeForTab,
  VerifyPhoneNumberWithTokenData,
  CheckTokenAndCodeData,
  TwoFactorAuthResponseData,
  VerifyPhoneCodeResponseData,
  BlogPost,
  PurchaseCheckInstance,
  ExtendedListRequestParams,
  NotificationsRequest,
  BlockUserRequest,
  QbpSyncType,
  LmSyncType,
  ExportType,
  GearListPaginatedRequest,
} from 'types';
import { BaseRestRequester } from './baseRestRequesrer';
import { CMS_PAGES, FEED_TABS } from 'consts';

export class RestRequester extends BaseRestRequester {
  constructor() {
    super();
  }

  // GENERAL
  public static async postData(api: string, data: any) {
    return await this.postForm(api, data);
  }

  // APPLICATION DATA
  public static async getShortLinkUrl(hash: string) {
    return await this.get(`/s/${hash}`, { return_link: '1' }, {}, {}, false);
  }

  public static async getMenuData() {
    return await this.get('/cms/menu');
  }

  public static async getPageData(id: CMSPageIds) {
    return await this.get(`/cms/pages/${id}`);
  }

  public static async getCustomPageData(url: string) {
    return await this.get(`/cms/custom-pages/${url}`);
  }

  public static async getCmsSizes(): Promise<CmsSizeResponse> {
    return await this.get('/cms/pages/onboarding/sizes');
  }

  public static async getBestBrands(limit: Number = 0) {
    return await this.get('/cms/best-brands', { limit });
  }

  // CUSTOMER DATA
  public static async getCustomerData(api: CustomerApi, params: QueryParams = {}) {
    const queryParams = { page: 1, limit: 100, ...params };
    return await this.get(`/${api}`, queryParams);
  }

  public static async getCustomerActivities(params?: QueryParams) {
    return await this.getCustomerData('activities', params);
  }

  public static async getCustomerBrands(params?: QueryParams) {
    return await this.getCustomerData('brands', params);
  }

  public static async getCustomerMarkets(params?: QueryParams) {
    return await this.getCustomerData('markets', params);
  }

  public static async getCustomerCategories(params?: QueryParams) {
    return await this.getCustomerData('categories', params);
  }

  public static async getCustomerCategoriesAsTree(params?: QueryParams) {
    return await this.getCustomerData('categories/tree', params);
  }

  public static async getCustomerColors(params?: QueryParams) {
    return await this.getCustomerData('colors', params);
  }

  public static async getCustomerTags(params?: QueryParams) {
    return await this.getCustomerData('tags', params);
  }

  public static async getCustomerCategorySizes(id: number, params?: QueryParams) {
    const queryParams = { page: 1, limit: 100, ...params };
    return await this.get(`/categories/${id}/sizes`, queryParams);
  }

  public static async getItemManagementSettings() {
    return await this.get(`/item-management/settings`);
  }

  public static async getReportReasons(type: ReportReasonOptions, cookies?: Cookies): Promise<CmsReportReasonResponse> {
    return await this.get(`/report-reasons/${type}`, {}, {}, cookies);
  }

  // CUSTOMER FAQ DATA
  public static async getFullFaq() {
    return await this.get('/faq');
  }

  public static async getFaqTopics() {
    return await this.get('/faq/topics');
  }

  public static async getFaqTopicAnswers(id: number | string) {
    return await this.get(`/faq/topics/${id}/answers`);
  }

  // CUSTOMER USER GUIDE DATA
  public static async getUserGuideArticleList() {
    return await this.get('/user-guide/articles');
  }

  public static async getUserGuideArticleInstance(slug: string) {
    return await this.get(`/user-guide/articles/${slug}`);
  }

  // CUSTOMER AMBASSADOR DATA
  public static async getAmbassadorList(
    page: number = 1,
    sort?: string,
    order?: string,
  ): Promise<ListResponseData<CustomerAmbassadorForList[]>> {
    return await this.get('/ambassadors', { page, sort, order, limit: 10 });
  }

  public static async getAmbassadorInstance(username: string): Promise<BaseResponseData<CustomerAmbassadorInstance>> {
    return await this.get(`/ambassadors/${username}`);
  }

  // CUSTOMER OFFERS DATA
  public static async getCustomerOffers(
    type: OfferType = 'sale',
    query?: CommonQuery,
    cookies?: Cookies,
  ): Promise<ListResponseData<CustomerOffer[]>> {
    return await this.get(`/offers/${type}`, { limit: 100, page: 1, ...query }, {}, cookies);
  }

  public static async postCustomerOffers(type: OfferType = 'sale', id: number, action: OfferAction) {
    return await this.post(`/offers/${type}/${id}/${action}`);
  }

  public static async postCustomerCounterOffers(type: OfferType = 'sale', id: number, action: OfferAction) {
    return await this.post(`/offers/${type}/${id}/counteroffer/${action}`);
  }

  public static async createCounterOffers(requested_price: number, id: number) {
    return await this.post(`/offers/sale/${id}/counteroffer/create`, {requested_price});
  }

  // CUSTOMER GEAR DATA
  public static async getGearFilters(params?: Record<string, QueryParams>) {
    const wrappedParams = params ? this.wrapQueryIntoBrackets('filters', params) : params;
    return await this.get('/gears/filters', wrappedParams);
  }

  public static async getGearList(params: GearListRequest = {}, cookies?: Cookies): Promise<GearResponse> {
    return await this.get('/gears', params, {}, cookies);
  }

  public static async getGearTags(params: GearListRequest = {}) {
    return await this.get('/gears/tags', params);
  }

  public static async getGearInstance(slug: string, cookies?: Cookies): Promise<BaseResponseData<ProductInstance>> {
    return await this.get(`/gears/${slug}`, {}, {}, cookies);
  }

  public static async getGearRelated(slug: string): Promise<BaseResponseData<ProductRelated>> {
    return await this.get(`/gears/${slug}/related`);
  }

  public static async getGearLike(slug: string, params: GearListPaginatedRequest = {}): Promise<GearResponse> {
    return await this.get(`/gears/${slug}/like`, params);
  }

  public static async getGearLikeSizes(slug: string, params: GearListPaginatedRequest = {}): Promise<GearResponse> {
    return await this.get(`/gears/${slug}/like-sizes`, params);
  }

  public static async getGearSimilarBrands(slug: string, params: GearListPaginatedRequest = {}): Promise<GearResponse> {
    return await this.get(`/gears/${slug}/similar-brands`, params);
  }

  public static async getTopSellers(params: GearListPaginatedRequest = {}): Promise<any> {
    return await this.get(`/customers/collections/top-sellers`, params);
  }

  public static async getPRPosts(): Promise<any> {
    return await this.get(`/cms/pr-posts`);
  }

  public static async getExternalReviews(): Promise<any> {
    return await this.get(`/cms/external-reviews`);
  }

  public static async getGearComments(slug: string, page: number = 1): Promise<ListResponseData<ProductComment>> {
    return await this.get(`/gears/${slug}/comments`, { page });
  }

  public static async postGearData(slug: string, api: string, data?: any): Promise<any> {
    return await this.post(`/gears/${slug}/${api}`, data);
  }

  // CUSTOMER ORDER DATA
  public static async getOrders(api: OrderType, query: QueryParams = {}, cookies?: Cookies) {
    return await this.get(`/orders/${api}`, query, {}, cookies);
  }

  public static async getOrder(id: number, cookies?: Cookies) {
    return await this.get(`/orders/${id}`, {}, {}, cookies);
  }

  public static async postOrderAction(id: number, action: OrderActionType, data?: Record<string, any>) {
    return await this.post(`/orders/${id}/${action}`, data);
  }

  // CUSTOMER ORDER PURCHASE DATA
  public static async getPurchasing(id: number, cookies?: Cookies, guestToken?: string) {
    //@ts-ignore
    return await this.get(`/orders/purchasing/${id}`, {}, { headers: { 'X-Order-Token': guestToken}}, cookies);
  }

  public static async postPurchasing(
    id: string | number,
    api: PurchaseApiType,
    data?: any,
    cookies?: Cookies,
    guestToken?: string,
  ): Promise<BaseResponseData<PurchaseInstance>> {
    //@ts-ignore
    return await this.post(`/orders/purchasing/${id}/${api}`, data, { headers: { 'X-Order-Token': guestToken, 'Content-type': 'application/json' }}, cookies);
  }

  public static async postCheckPurchasing(id: string | number, cookies?: Cookies, guestToken?: string): Promise<PurchaseCheckInstance> {
    //@ts-ignore
    return await this.post(`/orders/purchasing/${id}/check`, {}, {headers: { 'X-Order-Token': guestToken }}, cookies);
  }

  public static async cancelPurchasing(itemId: number) {
    return await this.delete(`/orders/purchasing/${itemId}`);
  }

  // CUSTOMER BALANCE DATA
  public static async getBalance(cookies?: Cookies): Promise<{ balance: number, currency: string }> {
    return await this.get('/balance', {}, {}, cookies);
  }

  public static async getTransactions(queryData?: BasicPageListRequestParams, cookies?: Cookies) {
    return await this.get('/balance/transactions', queryData, {}, cookies);
  }

  public static async getTransactionDetails(id: string, cookies?: Cookies) {
    return await this.get(`/balance/transactions/${id}`, {}, {}, cookies);
  }

  // CUSTOMER BLOG POSTS
  public static async getBlogPosts(
    page: number,
    limit: number,
    activities?: string[] | string,
  ): Promise<ListResponseData<BlogPost[]>> {
    return await this.get('/blog/posts', { page, limit, activities });
  }

  public static async getBlogPost(slug: string): Promise<BaseResponseData<BlogPost>> {
    return await this.get(`/blog/posts/${slug}`);
  }

  public static async subscribeToBlogPostActivity(email: string, slug: string) {
    return await this.post(`/blog/posts/${slug}/subscribe`, { email });
  }

  // AUTH DATA
  public static async authFirstStep(data: AuthData): Promise<TwoFactorAuthResponseData> {
    return await this.post('/auth/send-2fa', data);
  }

  public static async authSecondStep(data: AuthData): Promise<AuthPassedResponse> {
    return await this.post('/auth/login', data);
  }

  public static async authGetUser(cookies: Cookies) {
    return await this.get('/auth/user', {}, {}, cookies);
  }

  public static async authChangePassword(data: ChangePasswordData) {
    return await this.post('/auth/password/forgot', data);
  }

  public static async authResatPassword(data: ResetPasswordDate) {
    return await this.post('/auth/password/reset', data);
  }

  public static async authSocial(data: LoginSocialData) {
    return await this.post('/auth/login/social', data);
  }

  public static async verifyEmail(id: string, email: string, expires: string, signature: string, cookies?: Cookies) {
    return await this.post(`/email/verify/${id}/${email}?expires=${expires}&signature=${signature}`, {}, {}, cookies);
  }

  public static async resendVerifyEmail(email?: string) {
    return await this.post(`/email/send-verify`, {email});
  }

  public static async resetPhone(data: { email: string }) {
    return await this.post(`/phone/reset/request`, data);
  }

  public static async verifyPhone(data: VerifyPhoneNumberWithTokenData): Promise<VerifyPhoneCodeResponseData> {
    return await this.post(`/phone/reset/send-verify`, data);
  }

  public static async verifyPhoneCode(data: CheckTokenAndCodeData) {
    return await this.post(`/phone/reset/verify`, data);
  }

  // REGISTRATION
  public static async getRegisteredUser(cookies: Cookies) {
    return await this.get('/register/profile', {}, {}, cookies);
  }

  public static async registerUser(data: RegisterUserStep) {
    return (await this.post('/register', { ...data }, { noAccessToken: true })) as BaseResponseData<CurrentUser>;
  }

  public static async acceptAgreement() {
    return (await this.post('/register/accept-tac')) as BaseResponseData<CurrentUser>;
  }

  public static async passAccountType(
    data: {account_type: string},
  ) {
    return await this.post(`/register/account-type`, data);
  }

  public static async passRegistrationStep(
    step: RegistrationStep,
    data: VerifyPhoneNumberData | CheckCodeData | AccountInfoData | LocationData | ActivityStepData | BrandStepData,
    isForm?: boolean,
  ) {
    if (isForm) {
      return await this.postForm(`/register/step/${step}`, data);
    }
    return await this.post(`/register/step/${step}`, data);
  }

  public static async sendMeasurementsStepData(data: MeasurementsData) {
    return (await this.postForm('/register/step/measurements', data)) as BaseResponseData<CurrentUser>;
  }

  // ADMIN PANEL DASHBOARD
  public static async getDashboardData(api: DashboardApi, queryData?: DashboardQueryParams, cookies?: Cookies) {
    return await this.get(`/admin/dashboard/${api}`, queryData, {}, cookies);
  }

  // ADMIN PANEL ITEM MEDIA
  public static async adminUploadItemMedia(data: { media: File }, type: string, id: string, mediaId?: string) {
    return await this.postForm(`/admin/item-media/${type}/${id}${mediaId ? '/' + mediaId : ''}`, data);
  }

  public static async adminDeleteItemMedia(mediaId: string) {
    return await this.delete(`/admin/item-media/${mediaId}`);
  }

  public static async adminChangeItemMediaOrder(mediaId: string, order: number) {
    return await this.put(`/admin/item-media/${mediaId}/order`, { order });
  }

  // ADMIN PANEL AD CATALOGS
  public static async adminAdCatalogFilesData(cookies?: Cookies) {
    return this.get('/admin/gmc-items/file/data', {}, {}, cookies);
  }

  // ADMIN PANEL EXPORTS
  public static async adminStartExport(type: ExportType, data = {}) {
    return this.post(`/admin/exports/${type}/export`, data);
  }

  public static async adminExportStatus(type: ExportType) {
    return this.get(`/admin/exports/${type}/status`);
  }

  public static async adminExportDownload(type: ExportType) {
    return this.post(`/admin/exports/${type}/download`, {}, {}, {}, 'csv');
  }

  // ADMIN PANEL QBP
  public static async importQbpFile() {
    return await this.postForm('/admin/qbp/import');
  }

  public static async importStatus() {
    return await this.get('/admin/qbp/import/status');
  }

  public static async syncQbp(type: QbpSyncType, data = {}) {
    return await this.postForm(`/admin/qbp/sync/${type}`, data);
  }

  public static async qbpSyncStatus(type: QbpSyncType) {
    return await this.get(`/admin/qbp/sync/${type}/status`);
  }

  public static async qbpClearProducts() {
    return await this.post('/admin/qbp/items/clear');
  }

  public static async qbpCategoriesSync() {
    return await this.post('/admin/qbp/categories/sync/api');
  }

  // ADMIN PANEL LM
  public static async lmImportFile(data: { file: File, replace: boolean }) {
    return await this.postForm('/admin/lm/import', data);
  }

  public static async lmImportStatus() {
    return this.get('/admin/lm/import/status');
  }

  public static async lmSync(type: LmSyncType, data = {}) {
    return await this.postForm(`/admin/lm/sync/${type}`, data);
  }

  public static async lmSyncStatus(type: LmSyncType) {
    return this.get(`/admin/lm/sync/${type}/status`);
  }

  public static async lmClearProducts() {
    return await this.post('/admin/lm/items/clear');
  }

  public static async lmMoveMedia() {
    return await this.post('/admin/lm/media/move');
  }

  public static async lmMoveMediaStatus() {
    return this.get('/admin/lm/media/move/status');
  }

  // ADMIN PANEL COMMON REQUESTS
  public static async getAdminApiList(api: AdminApi, queryData?: ExtendedListRequestParams, cookies?: Cookies) {
    return await this.get(`/admin/${api}`, queryData, {}, cookies);
  }

  public static async getAdminApiItem(api: AdminApi, id: string, cookies?: Cookies, params: QueryParams = {}) {
    if (id === 'undefined' || id === 'create' || id.endsWith('.map')) {
      return {};
    }
    return this.get(`/admin/${api}/${id}`, params, {}, cookies);
  }

  public static async getAdminUserAddresses(id: string, cookies?: Cookies) {
    return this.get(`/admin/customers/${id}/addresses`, {}, {}, cookies);
  }

  public static async postAdminApiItemData(api: AdminApi, data: CommonAdminApiRequestData) {
    return await this.post(`/admin/${api}`, data);
  }

  public static async postAdminApiItemWithImageData(api: AdminApi, data: CommonAdminApiRequestData) {
    return await this.postForm(`/admin/${api}`, data);
  }

  public static async editAdminApiItem(api: AdminApi, data: CommonAdminApiRequestData, id: string) {
    return await this.put(`/admin/${api}/${id}`, data);
  }

  public static async manageAdminApiOption(
    api: AdminApi,
    id: string | number,
    option: string,
    data?: Record<string, any>,
  ) {
    return await this.post(`/admin/${api}/${id}/${option}`, data);
  }

  public static async editAdminApiItemWithImage(api: AdminApi, data: CommonAdminApiRequestData, id: string) {
    if (!CMS_PAGES.includes(id as CMSPageIds)) {
      data._method = 'PUT';
    }
    return await this.postForm(`/admin/${api}/${id}`, data);
  }

  public static async disableAdminApiItem(api: AdminApi, action: AdminApiDisableAction, id: string, blocked: boolean) {
    const field = action === 'hide' ? 'is_hide' : (action === 'publish') ? 'published' : 'blocked';
    return await this.put(`/admin/${api}/${id}/${action}`, { [field]: blocked });
  }

  public static async deleteAdminApiItem(api: AdminApi, id: string, query?: Record<string, any>) {
    return await this.delete(`/admin/${api}/${id}`, query);
  }

  public static async uploadMedia(apiUrl: string, data: Record<string, any>) {
    return await this.postForm(apiUrl, data);
  }

  // ADMIN PANEL / EXPORTS
  public static async exportCsvData(api: string, params: QueryParams = {}) {
    return await this.post(`/admin/${api}/export/csv`, params, {}, {}, 'csv');
  }

  public static async exportGearCsvData(params: QueryParams = {}) {
    return await this.post(`/admin/items/export/csv${this.composeQueryParams(params)}`, {}, {}, {}, 'csv');
  }

  public static async exportData(api: string, params: QueryParams = {}) {
    return await this.post(`/admin/${api}`, params, {}, {}, 'csv');
  }

  // ADMIN PANEL PR POSTS
  public static async saveNewPrPostOrder(id: number, direction: 'up' | 'down') {
    return await this.put(`/admin/content-management/pr-posts/${id}/order`, { direction });
  }

  // ADMIN PANEL / ORDER DISPUTES CHATS
  public static async getChatData(
    disputeId: string | number,
    route: string,
    queryParams?: QueryParams,
    cookies?: Cookies,
  ) {
    return await this.get(`/admin/order-disputes/${disputeId}/chat/${route}`, queryParams, {}, cookies);
  }

  public static async postChatData(
    disputeId: string | number,
    route: string,
    data: Record<string, any>,
    isForm?: boolean,
  ) {
    if (isForm) {
      return await this.postForm(`/admin/order-disputes/${disputeId}/chat/${route}`, data);
    }
    return await this.post(`/admin/order-disputes/${disputeId}/chat/${route}`, data);
  }

  // ADMIN PANEL / SUPPORT TICKETS
  public static async postSupportTicketStatus(id: number, status: string) {
    return await this.put(`/admin/support-tickets/${id}/status`, { status });
  }

  // ADMIN PANEL / SUPPORT TICKETS / CHAT
  public static async postSupportChatData(id: string | number, data: Record<string, any>, isForm?: boolean) {
    if (isForm) {
      return await this.postForm(`/admin/support-tickets/${id}/chat/media/upload`, data);
    }
    return await this.post(`/admin/support-tickets/${id}/chat`, data);
  }

  // ADMIN PANEL / PROFILE
  public static async changeAdminAccountField(data: Record<string, string>, field: string) {
    return await this.put(`/admin/profile/${field}`, data);
  }

  public static async verifyAdminPhone(data: { code: string }) {
    return await this.post('/admin/profile/phone/verify', data);
  }

  // PROFILE
  public static async getProfileData(api: ProfileApi, cookies?: Cookies, options?: QueryParams) {
    return await this.get(`/profile/${api}`, options, {}, cookies);
  }

  public static async postProfileData(
    api: ProfileApi | string,
    data: any = {},
    params: QueryParams = {},
    cookies?: Cookies,
  ) {
    return await this.post(`/profile/${api}`, data, params, cookies);
  }

  public static async postProfileInfo(data: ProfileInfo) {
    return await this.postForm('/profile/info', data);
  }

  public static async updateProfileData(api: ProfileApi | string, data: any) {
    return await this.post(`/profile/${api}`, data);
  }

  public static async socialDisconnectProfile(provider: string) {
    return await this.post(`/profile/social-disconnect/${provider}`);
  }

  public static async checkSocialConnect(token: { token: string }) {
    return await this.post('/profile/social-connect', token);
  }

  public static async editProfileData(api: ProfileApi, id: string, data: Record<string, any>) {
    return await this.put(`/profile/${api}/${id}`, data);
  }

  public static async deleteProfileData(api: ProfileApi, id: string, query?: Record<string, any>) {
    return await this.delete(`/profile/${api}/${id}`, query);
  }

  public static async deleteProfileSubscription(api: ProfileApi, data: any) {
    return await this.delete(`/profile/${api}`, undefined, {}, {}, data);
  }

  public static async deleteProfile() {
    return await this.delete('/profile');
  }

  public static async switchAccountType(data: {account_type: string, business_name?: string}) {
    return await this.post('/profile/account-type', data);
  }

  public static async getIdentityVerificationData() {
    return await this.get('/profile/identity');
  }

  public static async createIdentityVerificationSession() {
    return await this.post('/profile/identity');
  }

  // PROFILE PAYMENT
  public static async getStripePaymentIntent() {
    return await this.post(`/profile/payment-methods/stripe/intent`);
  }

  // CUSTOMER BALANCE
  public static async getCustomerBalance(cookies?: Cookies) {
    return await this.get('/balance', {}, {}, cookies);
  }

  public static async postBalancePayout(data: PayoutProps, cookies?: Cookies) {
    return await this.post('/balance/payout', data, {}, cookies);
  }

  public static async getCustomerTransactions(cookies?: Cookies, params?: BasicPageListRequestParams) {
    return await this.get('/balance/transactions', params, {}, cookies);
  }

  // CUSTOMER CUSTOMERS
  public static async searchCustomers(queryData: BasicSearchListRequestParams) {
    return await this.get('/customers', queryData);
  }

  public static async getCustomerProfile(username: string, cookies?: Cookies) {
    return await this.get(`/customers/${username}`, {}, {}, cookies);
  }

  public static async getCustomerProfileDetails(
    username: string,
    api: CustomerProfileDetailApi,
    queryData: QueryParams = {},
    cookies?: Cookies,
  ) {
    return await this.get(`/customers/${username}/${api}`, queryData, {}, cookies);
  }

  public static async postCommonCustomerData(api: string, data?: any): Promise<any> {
    return await this.post(`/${api}`, data);
  }

  public static async postCustomerData(username: string, api: string, data?: any): Promise<any> {
    return await this.post(`/customers/${username}/${api}`, data);
  }

  // CUSTOMER REVIEWS
  public static async getReviews(queryData: ReviewsQuery, api: ReviewApi) {
    return await this.get(`/items-reviews/${api}`, queryData);
  }

  // CREATE / EDIT GEAR
  public static async getItemManagementData(
    api: ItemManagementApi | string,
    cookies?: Cookies,
    id?: string,
    query: QueryParams = {},
  ) {
    return await this.get(`/item-management/${api}${id ? '/' + id : ''}`, query, {}, cookies);
  }

  public static async getItemStockData(
    id: string,
    cookies?: Cookies,
    query: QueryParams = {},
  ) {
    return await this.get(`/item-management/items/${id}/inventory`, query, {}, cookies);
  }

  public static async putItemStockData(
    id: string,
    data: any,
    cookies?: Cookies,
  ) {
    return await this.put(`/item-management/items/${id}/inventory`, data, {}, cookies);
  }

  public static async saveItemManagementData(api: ItemManagementApi, data: any, id?: string) {
    return await this.post(`/item-management/${api}${id ? '/' + id : ''}`, { api_version: 2, ...data });
  }

  public static async getCharityInfo() {
    return await this.get(`/charity`, {});
  }

  public static async uploadItemMedia(data: { media: File }, type: string, id: string, mediaId?: string) {
    return await this.postForm(`/item-management/media/${type}/${id}${mediaId ? '/' + mediaId : ''}`, data);
  }

  public static async uploadRentalAgreement(data: CancellationFeeData, id: string) {
    return await this.postForm(`/item-management/cancellation-fee/${id}`, data);
  }

  public static async deleteItemMedia(mediaId: string) {
    return await this.delete(`/item-management/media/${mediaId}`);
  }

  public static async changeItemMediaOrder(mediaId: string, order: number) {
    return await this.put(`/item-management/media/${mediaId}/order`, { order });
  }

  public static async deleteItem(id: string) {
    return await this.delete(`/item-management/items/${id}`);
  }

  public static async archiveItem(id: string) {
    return await this.post(`/item-management/items/${id}/archive`);
  }

  // SEARCH
  public static async categoriesSearch(query: string) {
    return await this.get('/search/categories', { query });
  }

  // BLACKLIST
  public static async blockUser(username: string, data: BlockUserRequest) {
    return await this.post(`/customers/${username}/block`, data);
  }

  // NOTIFICATIONS
  public static async getNotifications(data: NotificationsRequest) {
    return await this.get('/profile/notifications', data);
  }

  public static async setReadNotifications(id: string, data?: {seen: boolean}) {
    return await this.post(`/profile/notifications/${id}/read`, data);
  }

  // MESSAGES
  public static async getMessagesRooms(data: MessagesRoomsRequest, cookies?: Cookies) {
    return await this.get(
      '/messages/rooms',
      {
        ...data,
        archived: data.archived ? '1' : '0',
      },
      {},
      cookies,
    );
  }

  public static async getMessagesRoom(id: string, cookies?: Cookies) {
    return await this.get(`/messages/rooms/${id}`, {}, {}, cookies);
  }

  public static async getMessagesRoomWithUser(userId: string, cookies?: Cookies) {
    return await this.get(`/messages/rooms/customers/${userId}`, {}, {}, cookies);
  }

  public static async postMessagesRoomWithUser(userId: string, cookies?: Cookies) {
    return await this.post(`/messages/rooms/customers/${userId}`, {}, {}, cookies);
  }

  public static async getMessagesRoomWithItem(itemId: string, user_id?: string, cookies?: Cookies) {
    return await this.get(`/messages/rooms/items/${itemId}`, {user_id}, {}, cookies);
  }

  public static async postMessagesRoomWithItem(itemId: string, cookies?: Cookies) {
    return await this.post(`/messages/rooms/items/${itemId}`, {}, {}, cookies);
  }

  public static async postMessagesRoomWithSupport(cookies?: Cookies) {
    return await this.post(`/messages/rooms/support-tickets`, {}, {}, cookies);
  }

  public static async saveMessagesRoomInArchive(id: string, archived: boolean, cookies?: Cookies) {
    return await this.post(
      `/messages/rooms/${id}/archive`,
      {
        archived,
      },
      {},
      cookies,
    );
  }

  public static async getMessagesRoomMessages(id: string, page: number, fromTime?: number, cookies?: Cookies) {
    return await this.get(
      `/messages/rooms/${id}/messages`,
      {
        page,
        from_time: fromTime,
      },
      {},
      cookies,
    );
  }

  public static async postMessagesRoomMessage(id: string, data: MessagesRoomMessageRequest, cookies?: Cookies) {
    return await this.post(`/messages/rooms/${id}/messages`, data, {}, cookies);
  }

  public static async uploadMessagesRoomMedia(roomId: string, media: File, cookies?: Cookies) {
    return await this.postForm(
      roomId ? `/messages/rooms/${roomId}/messages/upload-media` : '/messages/upload-media',
      {
        media,
      },
      {},
      cookies,
    );
  }

  public static async readMessagesRoomMessages(id: string, cookies?: Cookies) {
    return await this.post(`/messages/rooms/${id}/messages/read`, {}, {}, cookies);
  }

  // FEED
  public static async feed(page: number = 1, fromTime: number | null = null, tab?: DealTypeForTab, cookies?: Cookies) {
    const queryData: QueryParams = {
      page,
      from_date: fromTime,
    };
    if (tab && FEED_TABS.includes(tab) && tab !== 'all') {
      queryData.type = tab;
    }
    return await this.get('/feed', queryData, {}, cookies);
  }

  public static async viewFeedEvents(events: string[], cookies?: Cookies) {
    return await this.post(
      '/feed/view',
      {
        events,
      },
      {},
      cookies,
    );
  }

  public static async feedAdditionalData(cookies?: Cookies) {
    return await this.get('/feed/additional', {}, {}, cookies);
  }

  // EVENTS
  public static async getEventCounters(cookies?: Cookies) {
    return await this.get('/events/counters', {}, {}, cookies);
  }

  public static async postContactFormData(data: any): Promise<any> {
    return await this.post(`/cms/contact-form`, data);
  }
}
